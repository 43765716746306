let children = []

children.push({
    path: "/contabilidad/generarasientoscontablescarga",
    name: "CON_GENERATED_ACCENTRIES",
    component: () =>
        import ("../views/Accounting/ConAcountigEntries.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Carga de Inf. para generar Asientos Contables", href: "/contabilidad/generarasientoscontablescarga" },
        ],
    },
});

children.push({
    path: "/contabilidad/generarasientoscontables",
    name: "CON_GENERATED_ACCOUN",
    component: () =>
        import ("../views/Accounting/ConAcountigEntriesGen.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Generar Asientos Contables", href: "/contabilidad/generarasientoscontables" },
        ],
    },
});

children.push({
    path: "/contabilidad/correlativo",
    name: "CON_CORRELATIVE",
    component: () =>
        import ("../views/Accounting/ConCorrelative.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Correlativo", href: "/contabilidad/correlativo" },
        ],
    },
});

children.push({
    path: "/contabilidad/reporteventas",
    name: "CON_RSALES",
    component: () =>
        import ("../views/Accounting/ConReportSales.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Reporte Ventas", href: "/contabilidad/reporteventas" },
        ],
    },
});
children.push({
    path: "/contabilidad/capital",
    name: "CON_LOAD_CAPITAL",
    component: () =>
        import ("../views/Accounting/ConAccountingCapital.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Carga Capital", href: "/contabilidad/capital" },
        ],
    },
});





export { children }