import axios from "axios";


//const baseURL = "https://impapiprod.azurewebsites.net/";
// const baseURL = "http://localhost:63130/api";

//const baseURL = "http://172.21.1.223:8989/api";

// const baseURL = "http://172.21.1.213:9091/api";
const baseURL = "https://190.223.56.84/api";

export default axios.create({
    baseURL,
});