<template>
  <v-card outlined>
    <v-row justify="center" v-show="items.length > 0">
      <v-col cols="12">
        <div :id="'containerBarDrill' + id"></div>
      </v-col>
    </v-row>
    <v-row justify="center" style="margin: auto" v-show="items.length == 0">
      <v-col cols="12">
        <div :id="'containerBarDrill' + id">
          NO SE HA CARGADO DATA PARA GENERAR REPORTE GRAFICO
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import exportdataInit from "highcharts/modules/export-data";
import accessibilityInit from "highcharts/modules/accessibility";
import drilldownInit from "highcharts/modules/drilldown";

stockInit(Highcharts);
exportingInit(Highcharts);
exportdataInit(Highcharts);
accessibilityInit(Highcharts);
drilldownInit(Highcharts);

export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    /* objDrilldown: {
            type: Array,
            default: [],

        }, */
    objDrilldown: null,
    title: {
      type: String,
      default: "Titulo",
    },

    visibleTitle: {
      type: Boolean,
      default: false,
    },

    subtitle: {
      type: String,
      default: "Subtitulo",
    },
    visibleSubTitle: {
      type: Boolean,
      default: false,
    },

    valueSuffix: {
      type: String,
      default: "",
    },
    colorByPoint: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: null,
    },
    dataLabels: {
      type: Boolean,
      default: true,
    },

    pointZ: {
      type: Boolean,
      default: false,
    },
    pointColor: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: 1,
    },
    number: {
      type: Boolean,
      default: false,
    },
    typeGraph: {
      type: String,
      default: "column",
    },
    legend: {
      type: Boolean,
      default: false,
    },
    thousandsSep: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    load() {
      Highcharts.chart("containerBarDrill" + this.id, {
        chart: {
          type: this.typeGraph,
        },
        title: {
          align: "left",
          //text: 'Browser market shares. January, 2018'
          text:
            this.visibleTitle == true
              ? '<span style="font-size:12px; font-weight: bold; color: rgb(66, 14, 47);">' +
                this.title +
                "</span><br>"
              : "",
        },
        subtitle: {
          align: "left",
          //text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
          text:
            this.visibleSubTitle == true
              ? '<span style="font-size:10px; font-weight: bold; color: rgb(56, 44, 7);">' +
                this.subtitle +
                "</span><br>"
              : "",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        legend: {
          enabled: this.legend,
          /* layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle' */
        },
        plotOptions: {
          series: {
            /* pointStart: 1,
                        label: {
                            connectorAllowed: false
                        }, */
            borderWidth: 0,
            dataLabels: {
              enabled: this.dataLabels,
              //format: '{point.y}'
              //                            format: this.pointZ ? '{point.z:.2f}  LOT <br>' + '<span style="font-size:10px; fill: black; text-decoration:;"> ' + this.number == false ? ' {point.y:.2f} ': ' {point.y} ' + this.valueSuffix +'</span>' : '<span style="font-size:10px; fill: black; text-decoration:;">' + this.number == false ? ' {point.y:.2f} ' : ' {point.y} ' + this.valueSuffix +'</span>'
              format: this.pointZ
                ? '{point.z}  LOT <br> <span style="font-size:10px; fill: black; text-decoration:;">{point.y:.2f} ' +
                  this.valueSuffix +
                  "</span>"
                : !this.number
                ? '<span style="font-size:10px; fill: black; text-decoration:;">{point.y:.2f} ' +
                  this.valueSuffix +
                  "</span>"
                : !this.thousandsSep
                ? '<span style="font-size:10px; fill: black; text-decoration:;">{point.y} ' +
                  this.valueSuffix +
                  "</span>"
                : '<span style="font-size:10px; fill: black; text-decoration:;">{point.y:,.0f} ' +
                  this.valueSuffix +
                  "</span>",
            },
          },
        },

        tooltip: {
          //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
          pointFormat: !this.number
            ? ' <span style="color:{point.color}">\u25CF</span> <b>{point.valueName} {point.name}: </b>  <b>{point.y:.2f} ' +
              this.valueSuffix +
              "</b><br>"
            : ' <span style="color:{point.color}">\u25CF</span> <b>{point.valueName} {point.name}: </b>  <b>{point.y}' +
              this.valueSuffix +
              "</b><br>",

          //pointFormat: ' <span style="color:{point.color}">\u25CF</span> <b>{point.name}: </b>  <b>' + this.number == false ? '{point.y:.2f} ' : ' {point.y} ' + this.valueSuffix + '</b><br>'
        },

        series: [
          {
            /* zoneAxis: 'value', */
            name: this.name,
            colorByPoint: this.colorByPoint,
            /* dataSorting: {
                        enabled: true,
                        sortKey: ''
                    }, */
            data: this.items,
          },
        ],
        drilldown: {
          breadcrumbs: {
            position: {
              align: "right",
            },
          },
          series: this.objDrilldown,
        },
        /* responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 1000
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                } */
      });
      Highcharts.setOptions({
        lang: {
          thousandsSep: ",",
        },
      });
    },
  },

  mounted() {
    this.load();
  },
  watch: {
    items() {
      this.load();
    },
  },
};
</script>

<style>
.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

input[type="number"] {
  min-width: 50px;
}
</style>
