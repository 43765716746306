import Service from "../Service";

const resource = "Requirement/";

export default {
    ConceptService(requestID) {
        return Service.post(
            resource + "concept", {}, {
                params: { requestID: requestID },
            }
        );
    },

    AccountConceptService(conName, requestID) {
        return Service.post(
            resource + "account", {}, {
                params: { ConName: conName, requestID: requestID },
            }
        );
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },



    save(obj, requestID, AccID) {
        if (!AccID)
            return Service.post(resource + "save", obj, {
                params: { requestID: requestID },
            });
        //Guardra Circuito
        else return Service.post(resource + "saveCircuit", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },
    conformityhistory(parameters,requestID) {
            return Service.post(resource+"conformityhistory", parameters, {
            params: { requestID },
        });
    },



};